import React from "react";
import Layout from "../components/layout";
import { Container, Row, Col } from "react-bootstrap";
import { IoPricetag, IoTime } from "react-icons/io5";
import Seo from "../components/seo";

const Delivery = () => {
    return (
        <Layout>
            <Seo
                title="Lieferung - BIKESAFE"
                description="Bei bikesafe.de sind wir stets bemüht, Ihnen Ihre Bestellung pünktlich zuzustellen. Sollte jedoch einmal ein Artikel für längere Zeit nicht vorhanden sein, wird sich einer unserer Kundendienstmitarbeiter per E-Mail oder Telefon mit Ihnen in Verbindung setzen, um Sie über den aktuellen Status Ihrer Bestellung zu informieren."
            />
            <Container>
                <Row>
                    <Col sm="12">
                        <h1 className="mt-5 mb-5">
                            Kostenlose Lieferung für alle Bestellungen
                        </h1>
                        <p className="flow-text">Bei bikesafe.de sind wir stets bemüht, Ihnen Ihre Bestellung pünktlich zuzustellen. Sollte jedoch einmal ein Artikel für längere Zeit nicht vorhanden sein, wird sich einer unserer Kundendienstmitarbeiter per E-Mail oder Telefon mit Ihnen in Verbindung setzen, um Sie über den aktuellen Status Ihrer Bestellung zu informieren.</p>
                        <h2 className="mt-5 mb-5"><IoPricetag /> Wie hoch sind die Versandkosten?</h2>
                        <p className="flow-text">
                            Die Lieferung ist für Deutschland meistens kostenlos. Ein kleiner Aufpreis kann für die Lieferung an etwas entfernteren Standorten dazu gerechnet werden.</p>
                        <h2 className="mt-5 mb-5"><IoTime /> Wie lange dauert die Lieferung?</h2>
                        <p className="flow-text">Die Lieferzeiten sind je nach Bestellung und Produkt unterschiedlich. Die geschätzten Lieferzeiten werden, wenn möglich, für jedes Produkt angezeigt. Die exakten Lieferzeiten können wir jedoch nicht verbindlich angeben.</p>
                    </Col>
                </Row>
            </Container>
        </Layout>
    )
}

export default Delivery;